import React, { useState, useEffect } from 'react';
import { Box, Button, TextInput, FormField, Heading, Layer, Text, Image } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser , signinWithGoogle} from '../redux/actions/authActions';
import googleLogo from "../assets/Google.webp"

const LoginModal = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const loginToken = useSelector(state => state.auth.token);
  console.log(loginToken)

  // Reset error on modal close or after successful login
  useEffect(() => {
    if (loginToken) {
      setError('');  // Clear error on successful login
      onSuccess();
    }
  }, [loginToken, onSuccess]);

  // Function to validate email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to validate the password (at least 6 characters)
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleLogin = async () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email');
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 6 characters');
      return;
    }

    try {
      await dispatch(loginUser(email, password)); // Attempt to log in
      if (!loginToken) {
        setError('Invalid email or password');  // Show error if login fails
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  // Placeholder for Google login handler
  const handleGoogleLogin = async () => {
    try {
      await dispatch(signinWithGoogle()); // Attempt to log in
      if (!loginToken) {
        setError('Invalid email or password');  // Show error if login fails
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" width="medium">
        <Heading level="3" margin="none">Login to Your Account</Heading>

        {error && <Text color="status-critical" margin={{ bottom: 'small' }}>{error}</Text>}

        {/* <FormField label="Email">
          <TextInput
            placeholder="Enter your email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            type='email'
          />
        </FormField>
        <FormField label="Password">
          <TextInput
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormField>

        <Box direction="row" gap="small" margin={{ top: "medium" }}>
          <Button label="Login" primary onClick={handleLogin} />
          <Button label="Cancel" onClick={onClose} />
        </Box> */}

        <Box align="center" margin={{ top: "medium" }}>
          <Button onClick={handleGoogleLogin} plain>
            <Box direction="row" align="center" gap="small" pad="small" border={{ color: 'light-4', size: 'small' }}>
              <Image src={googleLogo} alt="Google logo" style={{ width: '80px', height: '50px' }} />
              <Text>Google signin</Text>
            </Box>
          </Button>
        </Box>
      </Box>
    </Layer>
  );
};

export default LoginModal;
