import React from 'react';
import { Box, Text, Menu, Grid } from 'grommet';
import {
  SettingsOption,
  Logout,
  Currency,
  DocumentText,
  Print,
  Alert,
  StatusWarning,
  User,
  Shop,
  MailOption,
  Configure,
  CreditCard,
  Clock,
} from 'grommet-icons';

const DashboardTopBar = ({
  shopkeeperData,
  earnings,
  pageCount,
  pendingJobs,
  alertCount,
  warningCount,
  onLogout,
  onSubscribeClick,
  onPrinterMgmtClick,
  onRateCardMgmtClick,
  onWorkingHoursClick,
  onRazorpayConfigClick,
  onMyDetailsClick,
  onGmailConfigClick,
}) => {
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      elevation="small"
      style={{ zIndex: 1 }}
    >
      {/* Shopkeeper Info */}
      <Box direction="row" align="center" gap="small">
        <Shop color="brand" size="medium" />
        <Box>
          <Text size="large" weight="bold">
            Welcome, {shopkeeperData?.shopName || 'Shopkeeper'}!
          </Text>
          <Text size="small">{shopkeeperData?.email || 'email'}</Text>
          <Text size="small">{shopkeeperData?.contactNumber || 'contact'}</Text>
          <Text size="small">{shopkeeperData?.city || 'city'}</Text>
        </Box>
      </Box>

      {/* Earnings, Page Count, Pending Jobs in Tiles */}
      <Grid columns={{ count: 3, size: 'auto' }} gap="medium">
        <Box
          direction="row"
          align="center"
          pad="small"
          background="light-1"
          round="small"
          elevation="small"
          gap="small"
        >
          <Currency color="brand" />
          <Text>Earnings Today: ${earnings || 0}</Text>
        </Box>
        <Box
          direction="row"
          align="center"
          pad="small"
          background="light-1"
          round="small"
          elevation="small"
          gap="small"
        >
          <DocumentText color="brand" />
          <Text>Pages Printed Today: {pageCount || 0}</Text>
        </Box>
        <Box
          direction="row"
          align="center"
          pad="small"
          background="light-1"
          round="small"
          elevation="small"
          gap="small"
        >
          <Print color="brand" />
          <Text>Pending Print Jobs: {pendingJobs || 0}</Text>
        </Box>
      </Grid>

      {/* Alerts and Warnings Counter */}
      <Box direction="row" gap="medium">
        <Box direction="row" align="center" gap="xsmall">
          <Alert color="status-critical" />
          <Text color="status-critical">Alerts: {alertCount}</Text>
        </Box>
        <Box direction="row" align="center" gap="xsmall">
          <StatusWarning color="status-warning" />
          <Text color="status-warning">Warnings: {warningCount}</Text>
        </Box>
      </Box>

      {/* Menu for Settings and Logout */}
      <Menu
        items={[
          { label: 'My Details', onClick: onMyDetailsClick, icon: <User /> },
          {
            label: (
              <Box direction="row" align="center" gap="small" style={{ opacity: 0.5, pointerEvents: 'none' }}>
                <CreditCard />
                <Text>Subscribe</Text>
              </Box>
            ),
          },
          { label: 'Printer Management', onClick: onPrinterMgmtClick, icon: <Configure /> },
          { label: 'Rate Card Management', onClick: onRateCardMgmtClick, icon: <Currency /> },
          { label: 'Working Hours Setup', onClick: onWorkingHoursClick, icon: <Clock /> },
          { label: 'Razorpay Configuration', onClick: onRazorpayConfigClick, icon: <CreditCard /> },
          { label: 'Gmail Configuration', onClick: onGmailConfigClick, icon: <MailOption /> },
          { label: 'Logout', onClick: onLogout, icon: <Logout /> },
        ]}
        icon={<SettingsOption color="brand" />}
        dropAlign={{ top: 'bottom', right: 'right' }}
      />
    </Box>
  );
};

export default DashboardTopBar;
