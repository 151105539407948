// src/App.js
import React from 'react';
import { Grommet, Box } from 'grommet';
import { Provider } from 'react-redux';
import Navigation from './navigation/Navigation';
import store from './redux/store/store';
import { BrowserRouter as Router } from 'react-router-dom';
import yellowTheme from './theme/yellowTheme';

function App() {
  return (
    <Provider store={store}>
      <Grommet full>
        <Box
          fill
          style={{
            // background: 'linear-gradient(135deg, #FFDD00 0%, #FFD700 100%)', // Gradient background
            minHeight: '100vh',
          }}
        >
          <Router>
            <Navigation />
          </Router>
        </Box>
      </Grommet>
    </Provider>
  );
}

export default App;
