// src/services/ServiceLoader.js

// Import both mock and actual services
import { FirebaseAuthService } from './FirebaseAuthService';
import { MockAuthService } from './MockAuthService';
import { MockShopkeeperService } from './MockShopkeeperService';
import {MockGmailService} from "./MockGmailService";
import {MockJobQueueService} from "./MockJobQueueService";
import {MockPrinterService} from "./MockPrinterService";
import {MockSubscriptionService} from "./MockSubscriptionService";
import {MockPortalStatisticsService} from "./MockPortalStatisticsService";
import { PortalStatisticsService } from './PortalStatisticsService';

// Create a flag to toggle between mock and actual services
//const useMock = process.env.REACT_APP_USE_MOCK === 'true'; // Set this in .env file
const useMock =  false; // Set this in .env file

const ServiceLoader = {
  // Decide which auth service to use
  getAuthService: () => {
    return useMock ? MockAuthService : FirebaseAuthService;
  },

  getShopkeeperService: () => {
    return useMock ? MockShopkeeperService : MockShopkeeperService;
  },


  getGmailService: () => {
    return useMock ? MockGmailService : MockGmailService
  },

  getJobQueueService: () => {
    return useMock ? MockJobQueueService : MockJobQueueService
  },

  getPrinterService: () => {
    return useMock ? MockPrinterService : MockPrinterService
  },

  getSubscriptionService: () => {
    return useMock ? MockSubscriptionService : MockSubscriptionService
  },

  getPortalStatisticsService: () => {
    return useMock ? MockPortalStatisticsService : PortalStatisticsService
  }

  // In future, we can add more services, such as for Shopkeeper or Payment services
  // getShopkeeperService: () => { return useMock ? MockShopkeeperService : ActualShopkeeperService; }
};

export default ServiceLoader;
