// src/services/PortalStatisticsService.js
import axios from 'axios';

export const PortalStatisticsService = {
  fetchPortalStatistics: async () => {
    try {
      const response = await axios.get('http://localhost:5001/api/portal/stats');
      return response.data; // assuming the API returns the statistics directly in `data`
    } catch (error) {
      console.error("Error fetching portal statistics:", error);
      throw error; // re-throw to handle in the calling function if needed
    }
  }
};
