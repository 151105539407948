// src/services/MockGmailService.js

export const MockGmailService = {
    // Mock configuration of Gmail account
    configureGmailAccount: async (uid, email, password) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // Simulate a successful configuration
          if (email && password) {
            resolve({
              uid,
              email,
              status: 'configured',
              message: 'Gmail account configured successfully.',
            });
          } else {
            reject({
              status: 'error',
              message: 'Invalid email or password.',
            });
          }
        }, 1000);
      });
    },
  };
  